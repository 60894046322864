import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Nav from "../components/Navbar/Nav";
import Footer from "../components/Footer/Footer";
import Whatsapp from "../components/Floating/Whatsapp";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_STORE_DETAILS } from "../Graphql/Query";
import { fetchStoreDetails } from "../redux/actions/graph";
import CurrencyPrefix from "../components/Utils/CurrencyPrefix";
import Marquee from "../components/MetalPrices/Marquee";
import Loader from "../components/Loader/Loader";
import { generateMCXRates } from "../functions";

// import { ROLES } from "../constants/RoleConstants";
const Main = () => {
  const navigate = useNavigate();

  const Prices = () => {
    // let data = [
    //   { name: "Gold", price: "5,400/gm" },
    //   { name: "Silver", price: "2,700/gm" },
    //   { name: "Diamond", price: "999/kt" },
    // ];

    const dispatch = useDispatch();
    const [getStoreDetails, storeDeet] = useLazyQuery(GET_STORE_DETAILS, {
      variables: {
        // domain: {
        // domain: "ssjewellery.goldclub.co",
        domain:
          // process.env.NODE_ENV == "development"
          //   ? "sjjewel.goldclub.co"
          //   : 
            window.location.hostname,
        // },
      },

      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        dispatch(fetchStoreDetails(data?.storeDetails));
        if (data?.storeDetails) {
          localStorage.setItem(
            `vjw-${window.location.hostname}rates`,
            JSON.stringify(data?.storeDetails?.metalRates)
          );
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

    useEffect(() => {
      getStoreDetails();
    }, []);

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
      <section className="flex items-center  text-white">
        {storeDeet?.loading && <Loader variant={"white"} />}
        {!storeDeet?.loading &&
          storeDeet?.data?.storeDetails?.metalRates &&
          Object.entries(
            generateMCXRates(storeDeet?.data?.storeDetails?.metalRates)
          ).map(
            (item) =>
              item[0]?.includes("Rate") &&
              !item[0]?.includes("diamond") &&
              item[1] !== 0 && (
                <div className="mx-2" key={item[0]}>
                  <span>
                    {capitalizeFirstLetter(item[0]?.split("Rate")[0])}:{" "}
                  </span>
                  <span>
                    <CurrencyPrefix />
                    {item[1].toFixed(2)}/- {/* Round off to 2 decimal places */}
                  </span>
                </div>
              )
          )}

        {!storeDeet?.loading && storeDeet?.error && (
          <>Something Went Wrong! Please Reload</>
        )}
      </section>
    );
  };

  return (
    <>
      <section className="w-full bg-black py-3 relative metalPricesDiv flex items-center justify-center md:px-32 px-8">
        <Marquee>
          <Prices />
        </Marquee>
      </section>
      <Nav />
      <section className="mainWrapper flex flex-col items-center justify-center w-full ">
        {(
          <section className="w-full ">
            <Outlet />
          </section>
        ) || (
          <div>
            {" "}
            <center>Welcome to Jewellery Application</center>
            <div>
              <Link to="/login">Please Login here</Link>
            </div>
          </div>
        )}
      </section>
      <Whatsapp />

      <section className="w-full">
        <Footer />
      </section>
    </>
  );
};

export default Main;
