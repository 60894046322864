import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_FAQ } from "../../Graphql/Query";

import Loader from "../Loader/Loader";
import ErrorMessage from "../Messages/ErrorMessage";
const FaqCard = ({ faq }) => {
  const [open, setOpen] = useState(false);
  return (
    <section className="faqCard mb-8  shadow-sm rounded">
      <div className="border-2 border-slate-300 shadow rounded md:w-[16.2rem] w-[16rem] lg:w-[18.2rem] min-h-[15.2rem]">
        <div
          className="flex flex-col items-center justify-between px-2"
          onClick={() => setOpen(!open)}
        >
          <img
            className="h-[6rem] w-[6rem] mt-1.5"
            src={faq?.logo || `vjw-${window.location.hostname}logo`}
            alt=""
          />
          <h3 className="p-4 faq_question font-medium text-xl">
            {faq?.question}
          </h3>
          {open && (
            <div className="w-full">
              <p className="bg-transparent p-4 rounded-b-md rounded-l-md">
                {faq?.answer}
              </p>
            </div>
          )}
          <span className="cursor-pointer mb-7">
            <span className="underline underline-offset-4 text-sm font-medium">
              {open ? "Read Less" : "Read More"}
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};
const Faq = () => {
  const [faq, setFaq] = useState([
    {
      question: "100 % Money Back Guarantee",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, explicabo! Numquam hic esse reiciendis tempora error optio quia saepe sunt!",
      icon: "rupee.png",
    },
    {
      question: "One Year Replacement Warranty",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, explicabo! Numquam hic esse reiciendis tempora error optio quia saepe sunt!",
      icon: "discount.png",
    },
    {
      question: "Lifetime Exchange",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium, explicabo! Numquam hic esse reiciendis tempora error optio quia saepe sunt!",
      icon: "jewels.png",
    },
  ]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({ error: false, message: "" });

  const [getFaq, { loading }] = useLazyQuery(GET_FAQ, {
    onCompleted: (data) => {
      setFaq(data?.faqs);
      setSuccess(true);
    },
    onError: (err) => {
      setError({ error: true, message: "Failed to fetch FAQs" });
    },
  });

  useEffect(() => {
    setError({ error: false, message: "" });
    getFaq();
  }, []);
  return faq?.length > 0 ? (
    <section
      className="faqWrapper flex flex-col items-start justify-start w-full md:px-32 px-6 py-4"
      id="faq"
    >
      <h4 className="text-2xl mt-3.5 mb-2.5">FAQs</h4>
      <div className="mt-4 w-full flex flex-wrap md:gap-5 gap-3 items-start md:justify-start  justify-center">
        {loading && <Loader />}
        {!loading &&
          !error?.error &&
          faq &&
          faq?.map((faq, idx) => <FaqCard faq={faq} key={idx} />)}
        {error?.error && <ErrorMessage error={error?.message} />}
      </div>
    </section>
  ) : null;
};

export default Faq;
