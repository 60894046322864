import React, { useState, useEffect } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { MdLocationSearching } from "react-icons/md";
import { FaMapMarkerAlt, FaMapPin } from "react-icons/fa";
import { ImMobile } from "react-icons/im";
import { Link } from "react-router-dom";
import ErrorMessage from "../Messages/ErrorMessage";
import { useLazyQuery } from "@apollo/client";
import { GET_STORE_DETAILS } from "../../Graphql/Query";
const AddressDiv = ({ address }) => {
  return (
    <section className="flex items-center justify-between mb-8 w-full lg:w-[18rem] h-[10rem] bg-[#FFFFFF] shadow-sm rounded">
      <div className="storeLeft flex items-center justify-start">
        <div className="addressDetails ml-4">
          <h4 className="text-xl">{address?.companyName}</h4>
          <Link
            to={`https://www.google.com/maps?q=${address?.latitude},${
              address?.longitude
            }&z=${19}`}
            target="_blank"
            // className="bg-slate-50 rounded-full flex p-2 font-bold shadow-md"
          >
            <p className="text-md mt-1 flex justify-center items-center gap-2">
              <FaMapMarkerAlt size={14} /> {address?.streetAddress1},
              {" " + address?.streetAddress2},{" " + address?.countryArea}
            </p>
          </Link>

          {/* <p className="text-md mt-1 ">{address?.countryArea}</p> */}
          <p className="text-md mt-1 flex  items-center gap-2">
            <FaMapPin /> {address?.postalCode}
          </p>
          <p className="mt-1  flex items-center gap-2">
            <ImMobile size={12} /> {address?.phone}
          </p>
        </div>
      </div>
      {/* <div className="storeRight">
        <Link
          to={`https://www.google.com/maps?q=${address?.latitude},${
            address?.longitude
          }&z=${19}`}
          target="_blank"
          className="bg-slate-50 rounded-full flex p-2 font-bold shadow-md"
        >
          <MdLocationSearching />
        </Link>
      </div> */}
    </section>
  );
};
const StoreAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [getStoreDetails, storeDeet] = useLazyQuery(GET_STORE_DETAILS, {
    variables: {
      // domain: {
      // domain: "ssjewellery.goldclub.co",
      domain:
        process.env.NODE_ENV == "development"
          ? "sjjewel.devgoldclub.in"
          : window.location.hostname,
      // },
    },

    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setAddresses(data?.storeDetails?.user?.addresses);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    getStoreDetails();
  }, []);
  return (
    <section className="flex flex-col items-start justify-start mt-4 w-full bg-[#F9F9FA] md:px-32 px-6 py-4">
      <h4 className="text-2xl  mt-3.5 mb-2.5">Store Locations</h4>
      {addresses?.length > 0 ? (
        <div className="storeAddressWrapper md:mt-4 mt-2.5 w-full flex flex-wrap md:gap-6 gap-3">
          {addresses.map(
            (address, idx) =>
              !address.isDefaultShippingAddress &&
              !address.isDefaultBillingAddress && (
                <AddressDiv address={address} key={idx} />
              )
          )}
        </div>
      ) : (
        <div className="px-[38px]">
          <ErrorMessage
            error={"The store owner still hasn't updated their store address."}
          />
        </div>
      )}
    </section>
  );
};

export default StoreAddress;
