import React from "react";
import { useSelector } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";

const Whatsapp = () => {
  const number = useSelector((state) => state?.graph?.store?.storePhoneNumber);
  return (
    <a
      href={"https://wa.me/" + number}
      className="fixed bottom-3 right-3 h-[50px] w-[50px] z-[999999999] drop-shadow-lg bg-white flex justify-center items-center rounded-full "
    >
      {/* <img
        className="h-full w-full  rounded-full"
        src={process.env.PUBLIC_URL + "/whatsapp.jpg"}
        alt="whatsapp logo"
      /> */}
      <FaWhatsapp color="green" size={35} />
    </a>
  );
};

export default Whatsapp;
