// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation } from "swiper";

// import "swiper/css";
// import "swiper/css/navigation";

// import "./styles.css";

// const ImageCarousel = (props) => {
//   return props?.images?.length ? (
//     <div className="" id="bannerSlide">
//       <Swiper
//         loop={true}
//         rewind={true}
//         navigation={true}
//         modules={[Navigation, Autoplay]}
//         className="mySwiper"
//         autoplay={{
//           delay: 5000,
//           disableOnInteraction: false,
//         }}
//       >
//         {props?.images &&
//           props?.images?.map((image, idx) => (
//             <SwiperSlide key={idx}>
//               <img src={image?.image} alt="" style={{ objectFit: "contain" }} />
//             </SwiperSlide>
//           ))}
//         {!props?.images && (
//           <>
//             <SwiperSlide className="swiper-slide">
//               <img
//                 className="swiper-image"
//                 src={
//                   "https://cdn.caratlane.com/media/static/images/V4/2023/CL/05-MAY/AppBanner/FSblog/Desktop_1920x694.webp"
//                 }
//                 alt=""
//               />
//             </SwiperSlide>
//             <SwiperSlide className="swiper-slide">
//               <img
//                 className="swiper-image"
//                 src={
//                   "https://cdn.caratlane.com/media/static/images/V4/2023/CL/05-MAY/AppBanner/Firstsalary/02/Desktop_1920x694.webp"
//                 }
//                 alt=""
//               />
//             </SwiperSlide>
//             <SwiperSlide className="swiper-slide">
//               <img
//                 className="swiper-image"
//                 src={process.env.PUBLIC_URL + "/collection_1.png"}
//                 alt=""
//               />
//             </SwiperSlide>
//           </>
//         )}
//       </Swiper>
//     </div>
//   ) : null;
// };

// export default ImageCarousel;

import React, { useState, useEffect } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import "./styles.css";

const ImageCarousel = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? props.images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "ArrowRight") {
        handleNext();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return props?.images?.length ? (
    <div
      className="relative flex justify-center items-center"
      style={{ width: "100vw" }}
    >
      <img
        className="carousel-image swiper-image"
        src={props.images[currentImageIndex].image}
        alt=""
      />
      <div className="arrow-container absolute top-0 bottom-0 flex justify-between items-center w-full">
        <div
          className="arrow-space hover:text-white text-slate-50 cursor-pointer hover:bg-slate-800/10"
          onClick={handlePrev}
        >
          <button className="arrow-button" style={{ fontSize: "4rem" }}>
            <MdOutlineKeyboardArrowLeft />
          </button>
        </div>
        <div
          className=" arrow-space hover:text-white text-slate-50 cursor-pointer hover:bg-slate-800/10"
          onClick={handleNext}
        >
          <button
            className="arrow-button"
            style={{ fontSize: "4rem", fontWeight: "bold" }}
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ImageCarousel;
