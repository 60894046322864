import React, { useCallback, useEffect, useState } from "react";

import SectionTitle from "../../components/Headings/SectionTitle";

import DisplayPrice from "../Utils/DisplayPrice";
import RTE from "../RTE";

// function ProductDetailCard(props) {

//   const metric = {
//     'Less Weight' : "gm",
//     'Gross Weight' : "gm",
//     'Net Weight': "gm",
//     'Making Charge' : props?.mcm,
//     'Wastage Charge': props?.wcm,
//     'Gemstone Details': "",
//     'Total Gemstone Weight': "gm",
//   }

//   return (
//     <>
//       {/* <div className='w-[45%] min-h-20 mt-3 font-bold flex flex-col  justify-start shadow-md border-2  ml-2 rounded-md p-4 text-sm'> */}
//       <div className='w-[100%] min-h-20 mt-3 font-bold flex flex-col  justify-start text-sm'>
//         <div className='flex items-center justify-between border-b-2 py-3 px-4'>
//         <span className='font-bold'>{props?.title}</span>
//             <span className='font-bold'>{props?.title != "Gemstone Details" &&
//               (props?.description || "Unavailable")}
//             {props?.title == "Gemstone Details" &&
//               JSON.parse(props?.description).map((deet) => (
//                 <div className='my-1'>
//                   <strong>{deet?.name} :</strong>{" "}
//                   <span>
//                     {deet?.shape || "N/A"}({deet?.size || "N/A"})
//                   </span>
//                 </div>
//               ))}
//               </span>
//           </div>
//         </div>
//     </>
//   );
// }

function ProductDetailCard(props) {
  let mcmValue;
  if (props?.mcm === "Percent") {
    mcmValue = "%";
  } else {
    mcmValue = props?.mcm;
  }

  let wcmValue;
  if (props?.wcm === "Percent") {
    wcmValue = "%";
  } else {
    wcmValue = props?.wcm;
  }

  const metric = {
    "Enter min weight range": "",
    "Gold Color": "",
    "Gold Carat": "ct.",
    Gender: "",
    Type: "",
    Occasion: "",
    "Gemstone Details": "",
    "Total Gemstone Weight": "ct.",
    "Less Weight": "gm",
    "Gross Weight": "gm",
    "Net Weight": "gm",
    "Making Charge": mcmValue,
    "Wastage Charge": wcmValue,
  };

  const detailsName = (name) => {
    if (name === "Metal Type") return "Metal";
    else if (name === "Gold Color") return "Color";
    else if (name === "Gold Carat") return "Purity";
    else if (name === "Enter min weight range") {
      return;
    } else if (name === "Enter max weight range") {
      return "Made-to-Order with range";
    } else if (name === "occasion") return "Occasion";
    else return name;
  };

  return (
    <>
      <div className="w-[100%] min-h-20  font-bold flex flex-col justify-start text-sm">
        {props?.title !== "Enter min weight range" && (
          <div className="flex items-center justify-between border-b-2 py-3 px-4">
            {props?.title !== "Enter min weight range" && (
              <span className="font-bold">{detailsName(props?.title)}</span>
            )}
            {props?.title !== "Enter min weight range" && (
              <span className="font-bold">
                {props?.title !== "Gemstone Details" &&
                  (props?.description || "Unavailable")}

                {metric.hasOwnProperty(props?.title) &&
                  ` ${metric[props?.title]}`}

                {/* {props?.title === "Gemstone Details" &&
                  JSON.parse(props?.description).map((deet) => (
                    <div className="my-1">
                      <strong>{deet?.name}:</strong>{" "}
                      <span>
                        {deet?.shape || "N/A"}({deet?.size || "N/A"})
                      </span>
                    </div>
                  ))} */}

                {props?.title === "Gemstone Details" && (
                  <>
                    {(() => {
                      try {
                        const parsedDescription = JSON.parse(
                          props?.description
                        );
                        return parsedDescription.map((deet) => (
                          <div className="my-1" key={deet?.name}>
                            <strong>{deet?.name}:</strong>{" "}
                            <span>
                              {deet?.shape || "N/A"}({deet?.size || "N/A"})
                            </span>
                          </div>
                        ));
                      } catch (error) {
                        console.error("Error parsing description:", error);
                        return <div>N/A</div>;
                      }
                    })()}
                  </>
                )}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
}

// const ProdInfo = (props) => {

//   let vd = props?.currentProduct;
//   let pd = props?.product?.data?.product;

//   // METAL PRICES
//   let metalType = pd?.attributes
//     .filter((attr) => attr?.attribute?.name == "Metal Type")[0]
//     ?.values[0]?.name?.toLowerCase();
//   let metalPurity = 0;

//   switch (metalType) {
//     case "gold":
//       metalPurity = pd?.attributes
//         .filter((attr) => attr?.attribute?.name == "Gold Carat")[0]
//         ?.values[0]?.name?.toLowerCase();
//       break;
//     case "silver":
//       metalPurity = pd?.attributes
//         .filter((attr) => attr?.attribute?.name == "Silver Carat")[0]
//         ?.values[0]?.name?.toLowerCase();
//       break;

//     case "platinum":
//       metalPurity = pd?.attributes
//         .filter((attr) => attr?.attribute?.name == "Platinum Carat")[0]
//         ?.values[0]?.name?.toLowerCase();
//       break;

//     default:
//       break;
//   }

//   console.log(metalPurity);

//   // GEMSTONES
//   // const isStudded =
//   //   pd?.attributes?.filter((attr) => attr?.attribute?.name == "Type")[0]
//   //     ?.values[0]?.name == "Studded" || false;
//   // let gemstoneDetails;

//   // let totalGemstonewt = 0;
//   // let totalGemstonePrice = 0;
//   // if (isStudded) {
//   //   gemstoneDetails = JSON.parse(
//   //     vd?.attributes?.filter(
//   //       (attr) => attr?.attribute?.slug == "gemstone-details"
//   //     )[0]?.values[0]?.value
//   //   );

//   return (
//     <>
//       <div>{metalType}:{metalPurity}</div>
//     </>
//   );
// };

const ProductDetails = (props) => {
  // const stringifiedProp = JSON.stringify(props, null, 2)

  // console.log(stringifiedProp);

  const [seeMore, setSeeMore] = useState(false);
  const [counter, setCounter] = useState(props?.quantity);

  useEffect(() => {
    if (counter < 1) setCounter(1);
  }, [counter]);

  const incrementQuantity = () => props?.increment();
  const decrementQuantity = () => props?.decrement();

  // const mcmAttribute = props?.attributes.find(attribute => attribute.attribute.id === "QXR0cmlidXRlVmFsdWU6NDIw");
  // console.log("Result", mcmAttribute);

  // Check if the attribute is found and has values
  // const mcm = mcmAttribute?.values[0]?.name || "DefaultFallbackValue";

  const wcmAttribute = props?.attributes.find(
    (attr) => attr.attribute.name === "Wastage Charge Mode"
  );
  const wcm = wcmAttribute ? wcmAttribute.values[0]?.name || "" : "";
  console.log("Result", wcmAttribute);

  const mcmAttribute = props?.attributes.find(
    (attr) => attr.attribute.name === "Making Charge Mode"
  );
  const mcm = mcmAttribute ? mcmAttribute.values[0]?.name || "" : "";
  console.log("Result", mcmAttribute);

  const gold = JSON.parse(
    JSON.stringify(
      props?.attributes.find((attr) => attr.attribute.name === "Metal Type")
        .values
    )
  )[0].name;
  const purity = JSON.parse(
    JSON.stringify(
      props?.attributes.find((attr) => attr.attribute.name === "Gold Carat")
        .values
    )
  )[0].name;

  // const gem = JSON.parse(JSON.stringify((props?.attributes.find(attr=>attr.attribute.name === "Gemstone").values)))[0].name;
  // const gems = gem.replace(/[\[\]"\']/g, '');

  const gemAllDetails = props?.attributes.find(
    (attr) => attr.attribute.id === "QXR0cmlidXRlOjg5"
  )?.values;
  let gemDetails = [];

  if (gemAllDetails && gemAllDetails.length > 0) {
    const jsonString = gemAllDetails[0].name;

    try {
      // Parse the "name" value into an array
      gemDetails = JSON.parse(jsonString);

      console.log("Gem Details: ", gemDetails);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      gemDetails = null;
    }
  }

  const [range, setRange] = useState("");
  const [lessWeight, setLessWeight] = useState(null);

  const specificAttributes1 = [
    "Enter min weight range",
    "Enter max weight range",
    "Metal Type",
    "Gold Color",
    "Gold Carat",
    "Type",
    "Gender",
    "occasion",
  ];

  const specificAttributes2 = [
    "Less Weight",
    "Gross Weight",
    "Net Weight",
    "Making Charge",
    "Wastage Charge",
    "Gemstone Details",
    "Total Gemstone Weight",
  ];

  useEffect(() => {
    let minWeight = "";
    let maxWeight = "";

    specificAttributes1.forEach((attributeName) => {
      const attribute = props?.attributes.find(
        (attr) => attr.attribute.name === attributeName
      );
      if (attribute && attribute.values[0]?.name) {
        if (attributeName === "Enter min weight range") {
          minWeight = attribute.values[0]?.name;
        }
        if (attributeName === "Enter max weight range") {
          maxWeight = attribute.values[0]?.name;
        }
      }
    });

    console.log(minWeight);
    console.log(maxWeight);

    setRange(`${minWeight}-${maxWeight} gms`);
  }, [props.product]);

  useEffect(() => {
    let grossWeight;
    let netWeight;

    specificAttributes2.forEach((attributeName) => {
      const attribute = props?.product?.attributes.find(
        (attr) => attr.attribute.name === attributeName
      );
      if (attribute && attribute.values[0]?.name) {
        if (attributeName === "Gross Weight") {
          grossWeight = parseFloat(attribute.values[0]?.name);
        }
        if (attributeName === "Net Weight") {
          netWeight = parseFloat(attribute.values[0]?.name);
        }
      }
    });
    const lessW = parseFloat(grossWeight - netWeight).toFixed(2);

    setLessWeight(lessW);
    console.log(lessWeight);
  }, [props.product]);

  // console.log(props?.product?.isAvailable);
  // console.log(lessWeight);

  return (
    <div className="productDetails flex flex-col items-start justify-start w-[95%] my-4">
      <h1 className="text-3xl font-bold">{props?.name}</h1>
      <SectionTitle title="Product Details" />

      {/* {JSON.stringify(props?.attributes.find(attr=>attr.attribute.name=="Metal Type").values)}
    {JSON.stringify(props?.attributes.find(attr=>attr.attribute.name=="Gold Carat").values)} */}

      <span className={seeMore ? "block" : "hidden"}>
        Set in {gold} {purity}k
        {gemDetails && gemDetails[0]?.name && (
          <>
            , with {gemDetails[0]?.shape}, {gemDetails[0]?.size}{" "}
            {gemDetails[0]?.name}
          </>
        )}
      </span>

      <div
        // className={seeMore ? "line-clamp-none w-full " : "line-clamp-1 w-full"}
        className={seeMore ? "h-0 overflow-hidden w-full " : "h-auto w-full"}
      >
        {/* <div className='mt-2 invisible'>""</div> */}

        {/* <div className="w-full flex justify-start flex-wrap relative">
          {props?.product?.attributes
            ?.filter(
              (attribute) =>
                specificAttributes.includes(attribute?.attribute?.name) &&
                attribute?.values[0]?.name &&
                attribute?.values[0]?.name.toLowerCase() !== "none"
            )
            .map(function (attribute) {
              if (
                attribute?.values[0]?.name !== "[none]" &&
                attribute?.values[0]?.name !== "[]" &&
                attribute?.values[0]?.name !== "none"
              ) {
                return (
                  <ProductDetailCard
                    key={attribute?.attribute?.name} // Ensure this is unique
                    title={attribute?.attribute?.name}
                    description={attribute?.values[0]?.name}
                    mcm={mcm}
                    wcm={wcm}
                  />
                );
              }
            })}
        </div> */}

        <div className="w-full flex justify-start flex-wrap relative">
          {props?.product?.isAvailable
            ? specificAttributes2.map((attributeName, index) => {
                const attribute = props?.product?.attributes.find(
                  (attr) => attr.attribute.name === attributeName
                );
                if (attribute && attribute.values[0]?.name) {
                  if (
                    (attributeName === "Gemstone Details" &&
                      attribute.values[0]?.name === "[]") ||
                    (attributeName === "Total Gemstone Weight" &&
                      attribute.values[0]?.name === "none")
                  )
                    return;
                  if (attributeName === "Less Weight") {
                    return (
                      <ProductDetailCard
                        key={index}
                        title={attributeName}
                        description={lessWeight}
                        mcm={mcm}
                        wcm={wcm}
                      />
                    );
                  } else {
                    return (
                      <ProductDetailCard
                        key={index}
                        title={attributeName}
                        description={attribute.values[0]?.name}
                        mcm={mcm}
                        wcm={wcm}
                      />
                    );
                  }
                }
                return null;
              })
            : specificAttributes1.map((attributeName, index) => {
                const attribute = props?.attributes.find(
                  (attr) => attr.attribute.name === attributeName
                );
                if (attribute && attribute.values[0]?.name) {
                  if (attributeName === "Enter min weight range") return;
                  if (attributeName === "Enter max weight range") {
                    return (
                      <ProductDetailCard
                        key={index}
                        title={attributeName}
                        description={range}
                        mcm={mcm}
                        wcm={wcm}
                      />
                    );
                  } else {
                    return (
                      <ProductDetailCard
                        key={index}
                        title={attributeName}
                        description={attribute.values[0]?.name}
                        mcm={mcm}
                        wcm={wcm}
                      />
                    );
                  }
                }
                return null;
              })}
        </div>
      </div>

      <div></div>

      <div
        className="seeMore text-indigo-600 text-md mt-2 underline"
        onClick={() => setSeeMore(!seeMore)}
      >
        {seeMore ? "See All" : "See Less"}
      </div>

      <div>
        <section className="w-100 flex flex-row gap-9 px-4 py-8">
          <div className="text-center">
            <img
              className="h-10 w-auto mx-auto"
              src={process.env.PUBLIC_URL + "/fast.svg"}
              alt="tTIMELY DELIVERY"
            />
            <p>TIMELY DELIVERY</p>
          </div>
          <div className="text-center">
            <img
              className="h-10 w-auto mx-auto"
              src={process.env.PUBLIC_URL + "/hallmark.png"}
              alt="HALLMARKED"
            />
            <p>HALLMARKED</p>
          </div>
          <div className="text-center">
            <img
              className="h-10 w-auto mx-auto"
              src={process.env.PUBLIC_URL + "/shipping.svg"}
              alt="FREE SHIPPING"
            />
            <p>FREE SHIPPING</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProductDetails;
